$theme-colors: (
  "primary": #9841e2,
  "secondary": #6c757d,
  "danger": #dc3545,
  "dark": #212529,
  "success": #198754,
  "info": #0dcaf0,
);
$input-placeholder-color: rgb(180, 180, 180);

@import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #252733;
  max-height: 100%;
  overflow: hidden;
  scrollbar-color: #9841e2;
  position: relative;
}

button {
  height: 46px;
}

input,
select {
  min-height: 45px;
}

.form-check-input {
  min-height: 10px;
}

textarea:focus,
input:focus,
input[type]:focus,
select:focus,
.uneditable-input:focus {
  border-width: 2px;
  border-color: #9841e2 !important;
  box-shadow: none !important;
  outline: 0 none;
}
.filter-select__value-container {
  height: 35px !important;
  max-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  font-weight: bold !important;
}

.filter-select__single-value {
  position: static !important;
  transform: none !important;
}

.filter-select__control {
  max-height: 35px !important;
  min-height: 35px !important;
  box-shadow: none !important;
}

.filter-select__placeholder {
  position: static !important;
  transform: none !important;
  margin-bottom: 10px;
  padding: 0 !important;
  margin: 0 !important;
}

.react-responsive-modal-modal {
  max-width: 90vw;
}

.tooltip {
  z-index: 10000 !important;
  overflow: visible !important;
}

.my-dropdown-slidedown {
  overflow-x: hidden;
}

.rdrMonthAndYearWrapper {
  margin-top: 0px;
  padding-top: 0px;
}

@import url("https:/fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9841e2;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #9841e2;
  border-radius: 20px;
}

.dragged {
  z-index: 3;
}

@import 'react-nice-dates/src/style.scss';


.nice-dates-day:before {
  background-color: #9841e2;
}

.nice-dates-day.-wide:before,
.nice-dates-day.-wide:after {
  background-color: #9841e2;
  align-self: center;
}

.nice-dates-day:after {
  border: 2px solid #9841e2;
}

.nice-dates-week-header_day {
  @media (max-width: 575px) {
    font-size: 10px !important;
  }
}



$theme-colors: (
  "primary": #9841e2,
  "secondary": #6c757d,
  "danger": #dc3545,
  "dark": #212529,
  "success": #198754,
  "info": #0dcaf0,
);
$input-placeholder-color: rgb(180, 180, 180);

@import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  color: #252733;
  max-height: 100%;
  overflow: hidden;
  scrollbar-color: #9841e2;
  position: relative;
}

button {
  height: 46px;
}

input,
select {
  min-height: 45px;
}

.form-check-input {
  min-height: 10px;
}

textarea:focus,
input:focus,
input[type]:focus,
select:focus,
.uneditable-input:focus {
  border-width: 2px;
  border-color: #9841e2 !important;
  box-shadow: none !important;
  outline: 0 none;
}
.filter-select__value-container {
  height: 35px !important;
  max-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  font-weight: bold !important;
}

.filter-select__single-value {
  position: static !important;
  transform: none !important;
}

.filter-select__control {
  max-height: 35px !important;
  min-height: 35px !important;
  box-shadow: none !important;
}

.filter-select__placeholder {
  position: static !important;
  transform: none !important;
  margin-bottom: 10px;
  padding: 0 !important;
  margin: 0 !important;
}

.react-responsive-modal-modal {
  max-width: 90vw;
}

.tooltip {
  z-index: 10000 !important;
  overflow: visible !important;
}

.my-dropdown-slidedown {
  overflow-x: hidden;
}

.rdrMonthAndYearWrapper {
  margin-top: 0px;
  padding-top: 0px;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9841e2;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #9841e2;
  border-radius: 20px;
}

.dragged {
  z-index: 3;
}

@import 'react-nice-dates/src/style.scss';


.nice-dates-day:before {
  background-color: #9841e2;
}

.nice-dates-day.-wide:before,
.nice-dates-day.-wide:after {
  background-color: #9841e2;
  align-self: center;
}

.nice-dates-day:after {
  border: 2px solid #9841e2;
}

.nice-dates-week-header_day {
  @media (max-width: 575px) {
    font-size: 10px !important;
  }
}


